import {navigate} from 'gatsby';

export const acceptInvitation = (barmej_referral_key) => {
  const data = new FormData();
  data.append('invite_type', 1);
  data.append('key', barmej_referral_key);
  return {
    types: [
      'ACCEPT_INVITATION_REQUEST',
      'ACCEPT_INVITATION_SUCCESS',
      'ACCEPT_INVITATION_FAILURE',
    ],
    config: {
      method: 'post',
      url: 'referral/accept-invitation/',
      data,
    },
  };
};

export const afterSignUp = (preferredTrackSlug) => () => {
  const {PARTNER} = process.env;
  const completeRegistrationEvent =
    PARTNER === 'BARMEJ'
      ? 'Complete Registration'
      : `${PARTNER} Complete Registration`;

  if (PARTNER !== 'BARMEJ') {
    navigate('/tracks');
  } else if (!preferredTrackSlug) {
    navigate('/start');
  } else {
    navigate(`/${preferredTrackSlug}`);
  }

  if (process.env.NODE_ENV === 'production') {
    // Trigger an event for GTM after the registration
    if (window.dataLayer) {
      window.dataLayer.push({
        event: completeRegistrationEvent,
      });
    }
  }
};

export default afterSignUp;
