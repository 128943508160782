import React, {useEffect} from 'react';
import {navigate} from 'gatsby';
import notification from 'core/libs/helpers/notification';
import {useDispatch} from 'react-redux';
import afterSignIn from 'modules/auth/actions/after-sign-in';
import WaitingMessage from 'modules/common/WaitingMessage';

const FuturexSSO = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('key');
  const preferredTrackSlug = urlParams.has('selectedTrack') ? urlParams.get('selectedTrack') : '';
  const isSignUp = false;

  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId) {
      dispatch({type: 'SIGN_IN_COMPLETE', response: {data: {key: sessionId, isSignUp}}});
      dispatch(
        afterSignIn({
          isSignUp,
          provider: 'futurex',
          sessionId,
          preferredTrackSlug,
        }),
      );
      navigate(`/${preferredTrackSlug}`);
    } else {
      dispatch({type: 'SIGN_IN_ERROR'});
      notification({id: 'server_error_try_again'});
      navigate('/auth/login');
    }
  }, []);

  return (
    <WaitingMessage>
      <h3>المرجو الانتظار</h3>
      <h5 className="pt2">جاري الدخول</h5>
    </WaitingMessage>
  );
};

export default FuturexSSO;
