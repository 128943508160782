import {fetchUser} from 'modules/user/actions/profile';
import {getProgress} from 'modules/user/actions/progress';
import {saveLoginProviderData} from 'modules/user/actions/login-provider';
import {afterSignUp} from './after-sign-up';

export const afterSignIn = ({isSignUp, provider, preferredTrackSlug} = {}) => (
  dispatch,
) => {
  dispatch(fetchUser({isSignIn: !isSignUp, isSignUp, provider}));
  dispatch(getProgress());

  // Storing the auth/login provider to use it to disable frontend features for FutureX users
  dispatch(saveLoginProviderData(provider));

  // if (!isSignUp) dispatch({type: 'TOGGLE_POINTS_SYSTEM_DISCOUNT_POPUP'});
  if (isSignUp) dispatch(afterSignUp(preferredTrackSlug));

};

export default afterSignIn;
